import validateForm from '@/common/validateForm'

export default {
    data: () => ({
        error: {},
        errorMsg: {}
    }),
    methods: {
        async validate(field = [], item = {}, regexValidate = []){
            // const regexValidate = [
            //     {
            //         name: 'field_name',
            //         regex: 'email' || 'numberOnly' || 'textOnly'
            //     }
            // ]
            const { countError, error, errorMsg } = await validateForm(field,item,this.error, this.errorMsg, regexValidate)
            this.error = error
            this.errorMsg = errorMsg
            return parseInt(countError)
        },
        resetError(variable){
            this.error[variable] = false
        }
      }
  }
  
  